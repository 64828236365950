<template>
  <div>
    <hero />

    <contents />
  </div>
</template>

<script>
  export default {
    components: {
      Contents: () => import('@/components/posts/Contents'),
      Hero: () => import('@/components/posts/Hero')
    }
  }
</script>
